import React from 'react';
import styles from './FullscreenImage.module.css';

const FullscreenImage = ({ imageList, currentIndex, onClose }) => {
  const currentImageSrc = imageList[currentIndex];

  return (
    <div className={styles.fullscreenContainer} onClick={onClose}>
      <div className={styles.fullscreenImageWrapper}>
        <img
          src={currentImageSrc}
          className={styles.fullscreenImage}
          alt={`Fullscreen Image ${currentIndex + 1}`}
        />
        <button className={styles.closeButton} onClick={onClose}>
          Close Full Screen
        </button>
      </div>
    </div>
  );
};

export default FullscreenImage;
