
import React from 'react';
import styles from './AboutMeModal.module.css';
import pro_img from '../assets/profile.jpg';

const AboutMeModal = ({ onClose }) => {
  return (
    <div className={styles.aboutMeModal}>
      <div className={styles.aboutMeModalContent}>
        <h2>About Me</h2>
        <img
          src={pro_img}
          alt="About Me............................"
          className={styles.image}
        />
        <p style={{ fontFamily: 'fantasy', fontSize: '25px' }}>
          I am a passionate macro photographer on a quest to capture the unseen wonders, the ones that we often ignore, 
          the ones that we usually shoo away and the ones that exist all around us. 
          Through my lens, I delve into the tiny realms that often go unnoticed, revealing the intricate beauty of 
          nature's Small Wonders & tiniest inhabitants.
        </p>
        <button className={styles.closeButton} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default AboutMeModal;
