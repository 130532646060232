// components/BannerSection.js

import React from 'react';
import styles from './BannerSection.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import {faCamera} from "@fortawesome/free-solid-svg-icons"

const BannerSection = ({ icon, text }) => {
  return (

<div>
    <div className={styles['banner-section']}>
     <p className={styles['p1']}>
          <a href="https://www.instagram.com/ssmall_wonderss/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram}  />
          <span style={{ marginLeft: '5px', fontSize: '13px' }}> INSTAGRAM </span>
          </a>
      </p>

      <p className={styles['p2']}>
          <FontAwesomeIcon icon={faCamera}  />
          <span style={{ fontFamily: 'cursive', fontSize: '13px' }}> SMALL   WONDERS <br />
           by <br />
           Srujan Surapaneni  </span>
      </p>

      <p className={styles['p3']}>
          <a href="https://www.facebook.com/profile.php?id=100095132328095" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook}  />
          <span style={{ marginLeft: '5px', fontSize: '13px' }}> Facebook </span>
          </a>
       </p>
  </div>
</div>
  );
};

export default BannerSection;
