
import React from 'react';
import styles from './GearModal.module.css';
import nikon_cam from '../assets/nikon_D750.png';
import nikon_105 from '../assets/nikon_105.png';
import nikon_60 from '../assets/nikon_60.png';
import em5_2 from '../assets/em5_2.jpeg';
import olympus_30 from '../assets/olympus_30.png';
import mf12 from '../assets/mf12.png';
import x_pro_o from '../assets/x_pro_o.png';
import xpro_2_n from '../assets/xpro_2_n.png';
import nikon_mf12_popeshield from '../assets/nikon_mf12_popeshield.png';


const GearModal = ({ onClose }) => {
  return (
    <div className={styles.gearModal}>
      <div>
        <p  className={styles.gearDesc} style={{ fontFamily: 'fantasy', fontSize: '20px' }}>I use both Nikon and Olympus right now. While I had my Nikon for a while now,  <br />
        Olympus is recent and I'm yet to buy more lens and try new shots and subjects. 
      </p>
        <div className={styles.gearModalContent}>
        <h3 style={{ fontFamily: 'fantasy', fontSize: '25px' }}>Nikon Group</h3>
        <img
          src={nikon_cam}
          alt="This is all my gear"
          className={styles.imageIcon}
        />
         <img
          src={nikon_105}
          alt="This is all my gear"
          className={styles.imageIcon}
        />
         <img
          src={nikon_60}
          alt="This is all my gear"
          className={styles.imageIcon}
        />
        <p style={{ fontFamily: 'fantasy', fontSize: '15px' }}>
          NIKON D750, NIKON 105MM MACRO, NIKON 60MM MACRO
        </p>
        </div>
        <div className={styles.gearModalContent}>
        <h3 style={{ fontFamily: 'fantasy', fontSize: '25px' }}>Olympus Group</h3>
        <img
          src={em5_2}
          alt="This is all my gear"
          className={styles.imageIcon}
        />
        <img
          src={olympus_30}
          alt="This is all my gear"
          className={styles.imageIcon}
        />
        <p style={{ fontFamily: 'fantasy', fontSize: '15px' }}>
          OLYMPUS (OMD) EM5 MARK II, OLYMPUS 30MM MACRO
        </p>
        </div>
        <div className={styles.gearModalContent}>
        <h3 style={{ fontFamily: 'fantasy', fontSize: '25px' }}>Flash, Triggers and Diffusers</h3>
        <img
          src={mf12}
          alt="This is all my gear"
          className={styles.imageIcon}
        />
         <img
          src={x_pro_o}
          alt="This is all my gear"
          className={styles.imageIcon}
        />
         <img
          src={xpro_2_n}
          alt="This is all my gear"
          className={styles.imageIcon}
        />
         <img
          src={nikon_mf12_popeshield}
          alt="This is all my gear"
          className={styles.imageIcon}
        />
        <p style={{ fontFamily: 'fantasy', fontSize: '15px' }}>
             GODOX/FLASHPOINT MF12, GODOX X PRO-O, GODOX X PRO II-N, POPESHIELD DIFFUSER
        </p>
        </div>
        <button className={styles.closeButton} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default GearModal;
