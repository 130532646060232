import React, { useState } from 'react';
import styles from './GalleryModal.module.css';
import FullscreenImage from './FullscreenImage'; 

// Icons
import image1Icon from '../images/icon/upd_ant1.jpg';
import image2Icon from '../images/icon/upd_spider1.jpg';
import image3Icon from '../images/icon/upd1_bee1.jpg';
import image4Icon from '../images/icon/upd2_bee1.jpg';
import image5Icon from '../images/icon/upd_jap_beetle1.jpg';
import image6Icon from '../images/icon/upd_ghopper1.jpg';


// Fullscreens
import image1Fs from '../images/fullscreen/upd_ant1.jpg';
import image2Fs from '../images/fullscreen/upd_spider1.jpg';
import image3Fs from '../images/fullscreen/upd1_bee1.jpg';
import image4Fs from '../images/fullscreen/upd2_bee1.jpg';
import image5Fs from '../images/fullscreen/upd_jap_beetle1.jpg';
import image6Fs from '../images/fullscreen/upd_ghopper1.jpg';

const GalleryModal = ({ onClose }) => {
  const [fullscreenImageIndex, setFullscreenImageIndex] = useState(null);

  const openFullscreenImage = (index) => {
    setFullscreenImageIndex(index);
  };

  const closeFullscreenImage = () => {
    setFullscreenImageIndex(null);
  };

  return (
    <div className={styles.galleryModal}>
      {fullscreenImageIndex !== null && (
        <FullscreenImage
          imageList={[
            image1Fs,
            image2Fs,
            image3Fs,
            image4Fs,
            image5Fs,
            image6Fs,
          ]}
          currentIndex={fullscreenImageIndex}
          onClose={closeFullscreenImage}
        />
      )}
      <div className={styles.galleryModalContent}>
        <div className={styles.closeButtonContainer}>
          <button className={styles.closeButton} onClick={onClose}>
            X
          </button>
        </div>
        <img
          src={image1Icon}
          className={styles.imageIcon}
          alt="Image 1"
          onClick={() => openFullscreenImage(0)}
        />
        <img
          src={image2Icon}
          className={styles.imageIcon}
          alt="Image 2"
          onClick={() => openFullscreenImage(1)}
        />
        <img
          src={image3Icon}
          className={styles.imageIcon}
          alt="Image 3"
          onClick={() => openFullscreenImage(2)}
        />
        <img
          src={image4Icon}
          className={styles.imageIcon}
          alt="Image 4"
          onClick={() => openFullscreenImage(3)}
        />
        <img
          src={image5Icon}
          className={styles.imageIcon}
          alt="Image 5"
          onClick={() => openFullscreenImage(4)}
        />
        <img
          src={image6Icon}
          className={styles.imageIcon}
          alt="Image 6"
          onClick={() => openFullscreenImage(5)}
        />
        <p>All my photographs here....</p>
      </div>
    </div>
  );
};

export default GalleryModal;
