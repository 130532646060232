import React from 'react';
import './App.css';
import BannerSection from './components/BannerSection';
import NavigationMenu from './components/NavigationMenu';


const App = () => {
  return (
    <div>
      <BannerSection />
      <NavigationMenu />
    </div>
  );
};

export default App;
