import React, { useState } from 'react';
import styles from './NavigationMenu.module.css';
import GalleryModal from './GalleryModal';
import GearModal from './GearModal';
import AboutMeModal from './AboutMeModal';

const NavigationMenu = ({
}) => {

  const [showGalleryModal, setShowGalleryModal] = useState(false);

  const openGalleryModal = () => {
    setShowGalleryModal(true);
  };

  const closeGalleryModal = () => {
    setShowGalleryModal(false);
  };

  const [showGearModal, setShowGearModal] = useState(false);

  const openGearModal = () => {
    setShowGearModal(true);
  };

  const closeGearModal = () => {
    setShowGearModal(false);
  };

  const [showAboutMeModal, setShowAboutMeModal] = useState(false);

  const openAboutMeModal = () => {
    setShowAboutMeModal(true);
  };

  const closeAboutMeModal = () => {
    setShowAboutMeModal(false);
  };

  return (
    <div className={styles.navigation}>
      <ul>
        <li onClick={openGalleryModal}>Gallery</li>
        <li onClick={openGearModal}>Gear</li> 
        <li onClick={openAboutMeModal}>About Me</li>
      </ul>

      {showGalleryModal && <GalleryModal onClose={closeGalleryModal} />}
      {showGearModal && <GearModal onClose={closeGearModal} />}
      {showAboutMeModal && <AboutMeModal onClose={closeAboutMeModal} />}
    </div>
  );
};

export default NavigationMenu;
